import * as React from "react"
import { graphql, Link } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PlayIcon from "../components/icons/play"
import Similar from "../components/shared/similar"
import AdSlot from "../components/shared/adSlot"
import PrismicLink from "../components/prismicLink"

const Channel = ({ data }) => {
    if (!data) return null
    const document = data.prismicChannel.data
    const settings = data.prismicGeneralSettings.data

    return (
        <Layout>
            <SEO
                title={
                    document.live_channel_seo_title?.text ||
                    document.live_channel_title?.text
                }
                description={document.live_channel_seo_description?.text}
                // ogImage={}
            />
            <div className="grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 pb-10 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="pb-4 border-b border-gray-300">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {RichText.asText(document.live_channel_title?.raw)}
                        </h1>
                    </div>

                    {/* Live Channel Top Article Slot */}
                    <AdSlot
                        html={settings.live_channel_top_article_slot?.text}
                    />

                    <div className="mt-4 pb-10 prose prose-lg max-w-none text-gray-600">
                        <RichText
                            render={document.live_channel_content?.raw}
                            serializeHyperlink={PrismicLink}
                        />

                        <div className="pt-2">
                            {/* Live Channel End Article Slot */}
                            <AdSlot
                                html={settings.live_channel_end_article?.text}
                            />
                        </div>
                    </div>
                </div>
                <div className="pb-10">
                    <h2 className="mb-4 text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                        Los enlaces
                    </h2>

                    {/* Live Channel Top Right Article Slot */}
                    <AdSlot html={settings.live_channel_top_right_slot?.text} />

                    <ul className="mb-6">
                        <li className="mb-2">
                            <Link
                                className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                                to={`/player-${data.prismicChannel.uid}`}
                            >
                                <PlayIcon className="mr-2" />
                                <span>{`${document.name.text} en directo a través del ordenador`}</span>
                            </Link>
                        </li>

                        <li className="mb-2">
                            {document.live_channel_android_link?.url.includes(
                                `/player-${data.prismicChannel.uid}`
                            ) && (
                                <Link
                                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                                    to={`/player-${data.prismicChannel.uid}`}
                                >
                                    <PlayIcon className="mr-2" />
                                    <span>{`${document.name.text} en directo a través de Android`}</span>
                                </Link>
                            )}
                            {!document.live_channel_android_link?.url.includes(
                                `/player-${data.prismicChannel.uid}`
                            ) && (
                                <a
                                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                                    href={
                                        document.live_channel_android_link?.url
                                    }
                                >
                                    <PlayIcon className="mr-2" />
                                    <span>{`${document.name.text} en directo a través de Android`}</span>
                                </a>
                            )}
                        </li>

                        <li>
                            {document.live_channel_iphone_link?.url.includes(
                                `/iframe-${data.prismicChannel.uid}`
                            ) && (
                                <Link
                                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                                    to={`/iframe-${data.prismicChannel.uid}`}
                                >
                                    <PlayIcon className="mr-2" />
                                    <span>{`${document.name.text} en directo a través de iPhone o iPad`}</span>
                                </Link>
                            )}
                            {!document.live_channel_iphone_link?.url.includes(
                                `/iframe-${data.prismicChannel.uid}`
                            ) && (
                                <a
                                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                                    href={
                                        document.live_channel_iphone_link?.url
                                    }
                                >
                                    <PlayIcon className="mr-2" />
                                    <span>{`${document.name.text} en directo a través de iPhone o iPad`}</span>
                                </a>
                            )}
                        </li>
                    </ul>

                    {/* Live Channel Right After Links Slot */}
                    <AdSlot
                        html={
                            settings.live_channel_right_after_links_slot?.text
                        }
                    />

                    {document.twitter_handle?.text && (
                        <a
                            className="twitter-timeline"
                            data-height="350"
                            href={`https://twitter.com/${document.twitter_handle?.text}`}
                        >
                            Tweets by ${document.twitter_handle?.text}
                        </a>
                    )}
                </div>
            </div>

            {/* Live Channel Suggestions Top Slot */}
            <div className="flex justify-center">
                <AdSlot
                    html={settings.live_channel_suggestions_top_slot?.text}
                />
            </div>

            <Similar channels={data.channels} type="live" />
        </Layout>
    )
}

export default withPreview(Channel)

export const query = graphql`
    query ChannelQuery($uid: String!) {
        prismicGeneralSettings {
            data {
                live_channel_top_article_slot {
                    text
                }
                live_channel_top_right_slot {
                    text
                }
                live_channel_right_after_links_slot {
                    text
                }
                live_channel_end_article {
                    text
                }
                live_channel_suggestions_top_slot {
                    text
                }
            }
        }
        prismicChannel(uid: { eq: $uid }) {
            uid
            data {
                live_channel_title {
                    text
                    raw
                }
                live_channel_seo_title {
                    text
                }
                live_channel_seo_description {
                    text
                }
                live_channel_content {
                    raw
                }
                live_channel_desktop_link {
                    url
                }
                live_channel_android_link {
                    url
                }
                live_channel_iphone_link {
                    url
                }
                name {
                    text
                }
                twitter_handle {
                    text
                }
            }
        }
        channels: allPrismicChannel {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
