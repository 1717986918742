import * as React from "react"
import ChannelCard from "./channelCard"

export default function Similar({ channels, type }) {
    return (
        <div className="bg-gray-50">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mx-auto px-6 py-10 max-w-7xl">
                {channels.nodes.map(channel => (
                    <ChannelCard
                        channel={channel}
                        type={type}
                        key={channel.uid}
                    />
                ))}
            </div>
        </div>
    )
}
